.grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.grid-header {
  background-color: #afd09e;
  color: #262626;
  font-weight: 600;
  font-size: 20px;
  min-height: 50px;
  top: 70px;
  position: sticky;
  padding: 10px 10px;
  text-align: center;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  margin-top: 70px;
}

.sticky-client {
  position: sticky;
  left: 0;
  z-index: 1;
  width: 80px;
}

.duplicate-mode {
  background-color: #ca8659;
}

.grid-item {
  padding: 10px;
  border: 1px dashed #ddd;
  min-height: 200px;
  min-width: 900px;
}

.client-info {
  min-width: 80px;
  background-color: #ffffff;
}

.grid-item:hover:not(.success-style):not(.failed-style):not(.grid-header):not(.client-info) {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: rgb(253, 253, 253);
  border: 1px solid #ddd;
}

.button {
  min-height: 100px;
  margin: 20px;
}

.cell-in-use {
  background-color: rgb(201, 201, 201);
}
.client-shown {
  display: none;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
  pointer-events: none;
}

.client-shown.show {
  display: block;
}
@keyframes scaleUpDown {
  0% {
    transform: scale(1);
    z-index: 1;
  }
  50% {
    transform: scale(1.05);
    z-index: 1;
  }
  100% {
    transform: scale(1);
    z-index: 1;
  }
}
