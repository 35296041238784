.add-modal {
  padding: 20px;
  position: absolute;
  width: 1000px;
  height: 500px;
  top: 20%;
  left: 23%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.edit-modal {
  padding: 20px;
  position: absolute;
  width: 1400px;
  height: 750px;
  top: 6%;
  left: 12%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.close-modal {
  position: absolute;
  right: 0;
  top: 18px;
  margin-right: 18px;
  padding: 8px;
}

.close-modal:hover {
  background-color: #dd0000;
  color: white;
}

#save-button {
  background-color: #156bc2;
  color: white;
  margin-right: 20px;
  width: 25%;
}

.add-shift {
  text-align: center;
}

.bottom-left {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 4px;
  padding: 15px;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 4px;
  z-index: 10000;
}

.trash-can-left {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.363);
  color: black;
  border-radius: 4px;
  z-index: 10000;
}

.trash-can-left:hover {
  transform: scale(1.1);
  animation: shake 0.3s ease-in-out;
  background-color: rgb(58, 58, 58);
  color: white;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  color: #1976d2;
}

@keyframes shake {
  0% { transform: rotate(0deg) translateY(0); }
  25% { transform: rotate(-10deg) translateY(-2px); }
  50% { transform: rotate(10deg) translateY(0); }
  75% { transform: rotate(-5deg) translateY(-2px); }
  100% { transform: rotate(0deg) translateY(0); }
}
