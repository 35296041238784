.h2-element {
    display: flex;
    justify-content: center;
    margin: 40px;
}

.div-range-date {
    display: flex;
    justify-content: center;
    font-weight: 700;
    margin: 20px;
    height: 90%;
}

.export-button {
    display: flex;
    justify-content: center;
    margin: 40px;
}

.fullname-label {
    display: flex;
    margin: 40px
}

.fullname-label-text {
    margin:  12px;
    margin-left: 107px;
    font-size: 17px;
    font-weight: 500;
}
