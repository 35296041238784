.map-container {
    height: 500px;
    width: 100%;
    z-index: 1;
}

.map-no-postcode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 600px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    text-align: center;
    justify-content: center;
    z-index: 1000;
    width: 100%;
}
