.center-text-editor {
    display: flex;
    justify-content: center;
}

.border-style {
    display: flex;
    justify-content: center;
    border: 2px solid #888686;
    border-radius: 20px;
    min-height: 550px;
    margin-top: 30px;
    width: 80%;
}

.left-button-style {
    display: flex;
    justify-content: right;
    margin-right: 120px;
    margin-top: 20px;
}