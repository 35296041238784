.center-button-div {
    display: flex; 
    justify-content: center;
    width: 100%;
}

.button-box {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 20;
}