.table-container {
    max-height: 652px;
    overflow-y: auto;
    overflow-x: hidden;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
    overflow-y: scroll;
}
  
.table th, .table td {
    height: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
}
  
.table thead th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
    text-align: center;
}

.table tbody tr:hover {
    background-color: #f1f1f1;
}

.table thead th:hover {
    cursor: pointer;
    background-color: lightgray;
}
