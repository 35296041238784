.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
}

.h2-element {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px !important;
    text-align: center;
    color: #333;
    margin-top: -40px !important;
}

.p-element {
    text-align: center;
    margin-top: -22px;
    margin-bottom: 35px;
    color: gray;
}

.timesheet-form {
    background: white;
    border: 1px solid #ddd;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container-div {
    padding: 45px 60px;
}

.timesheet-form .MuiTextField-root {
    width: 100%;
    max-width: 400px;
}

.export-button {
    height: 48px;
    width: 100%;
    max-width: 400px;
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.export-button:hover {
    background-color: #014ea1;
}

.export-button:disabled {
    background-color: #cce5ff;
    color: #fff;
}
