.wp-details-table {
  display: grid;
  grid-template-columns: 2fr;
  text-align: left;
  background-color: #74c365;
}

.wp-data {
  display: flex;
  justify-content: space-around;
  padding: 4px;
  font-weight: 500;
  border-right: 0.5px solid #000;
  border-left: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.shift-details-table {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: left;
  background-color: #74c365;
  border: 0.5px solid #000;
}

.details-data {
  padding: 4px;
  font-weight: 400;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.details-data.centered {
  text-align: center;
  font-weight: 600;
  border-right: 0;
}

.time-table {
  display: grid;
  grid-template-columns: 1.56fr 1.01fr 0.945fr 1.659fr 1.349fr 1.2fr;
  text-align: center;
  border-left: 0.5px solid #000;
}

.time-table-header {
  padding: 4px;
  background-color: #305496;
  color: white;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.time-table-data {
  padding: 4px;
  background-color: #74c365;
  color: black;
  border-right: 0.5px solid #000;
  font-weight: 500;
}

.worker-table {
  display: grid;
  grid-template-columns: 1.56fr 1.95fr 0.898fr 1.3fr 0.2fr 0.2fr 0.2fr 0.2fr 0.2fr 0.3fr 0.7fr;
  text-align: center;
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  border-top: 0.5px solid #000;
}

.worker-header {
  padding: 4px;
  font-weight: 600;
  border-right: 0.5px solid #000;
}

.worker-data {
  min-height: 20px;
  padding: 4px;
  border-right: 0.5px solid #000;
  border-top: 0.5px solid #000;
  font-size: 15px;
}

.show-notes {
  position: relative;
}

.notes-shown {
  display: none; 
  position: absolute; 
  top: 100%;
  right: 10px;
  min-width: 150px;
  min-height: 70px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
}

.notes-hover:hover + .notes-shown {
  display: block;
}

.show-postcode {
  position: relative;
}

.postcode-shown {
  display: none; 
  position: absolute; 
  top: 100%;
  right: 10px;
  min-width: 100px;
  min-height: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 1000;
}

.postcode-hover:hover + .postcode-shown {
  display: block;
}

.location-table {
  display: grid;
  grid-template-columns: 1.57fr 2.848fr 1.9fr 1.4fr;
}

.location-header {
  padding: 4px;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.location-data {
  padding: 4px;
}

.info-table {
  display: grid;
  grid-template-columns: 1.57fr 6.148fr;
}

.info-header {
  padding: 4px;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  border-left: 0.5px solid black;
}

.info-data {
  display: flex;
  padding: 4px;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.notes-table {
  display: grid;
  grid-template-columns: 2fr;
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.notes-hotel-table {
  display: grid;
  grid-template-columns: 2fr 0.51fr;
  border-left: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
}

.notes-header {
  padding: 4px;
  border-right: 0.5px solid #000;
  font-size: 17px;
}

.notes-data {
  display: flex;
  padding: 4px;
  border-right: 0.5px solid #000;
}

.success-style {
  box-shadow: 0 0 15px green;
  animation: scaleUpDown 0.5s ease;
}
  
.failed-style {
  box-shadow: 0 0 15px red;
}

.flip-container {
  perspective: 1000px;
}

.flip-shift {
  position: relative;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.flip-shift.flip {
  transform: rotateY(180deg);
}

.flip-shift-back {
  background-color: transparent;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
